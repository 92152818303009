@import "knacss";

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#mainfooter {
  margin-top: auto;
}

body {
  background: #22232b url("../img/random-grey-variations.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  > header, > footer {
    // pantone: Inkwell, classic spring/summer colors 2021
    background: #363945;
    color: lighten(#363945, 60%);
  }

  > header {
    border-bottom: .5rem solid darken($headings-link-color, 25%);
    //box-shadow: 0 .2rem .4rem #0d0d0d;
    a {
      color: $headings-link-color;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        color: $headings-link-color-hover;
        text-decoration: none;
      }
    }

    > div > nav {
      text-align: right;

      a {
        margin-left: 2rem;
      }
    }
  }

  > footer {
    border-top: .2rem solid darken($headings-link-color, 25%);
    font-size: 1.5rem;

    a {
      color: $headings-link-color;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        color: $headings-link-color-hover;
        text-decoration: none;
      }
    }
  }
}

#maincontent {
  a {
    color: $article-link-color;

    &:hover {
      color: $article-link-color-hover;
      text-decoration: underline;
    }
  }

  article,
  nav.sommaire {
    background: #363945;
    border-radius: 1rem;
    box-shadow: 0 0 .4rem #0d0d0d;
    color: $gray-300;

    header {
      h1 {
        line-height: inherit;
        text-shadow: .1rem .1rem .1rem #111;

        a {
          color: $headings-link-color;

          &:hover, &focus {
            color: $headings-link-color-hover;
          }
        }
      }

      small {
        color: $gray-500;
      }
    }

    h1, h2, h3 {
      color: lighten($headings-link-color, 5%);
    }

    pre > code {
      border-radius: 1rem;
      box-shadow: 0 0 .8rem #1a1a1a inset;
    }

    blockquote {
      font-style: oblique;
    }

    blockquote::before {
      content: none;
    }

    footer {
      clear: both;

      div {
        padding-top: .2rem;
        padding-bottom: .5rem;
        font-size: $font-size-base/1.5;
        color: $gray-500;

        span.tag {
          border: none;
          background: none;

          a {
            background: #363945;
            font-size: $font-size-base/1.5;
            padding: .5rem;
            border-radius: 1rem;
            box-shadow: 0 0 .4rem #222222;
          }
        }
      }
    }

    a {
      text-decoration: underline;
    }
  }
}

small.tag {
  background: none;

  a {
    background: #363945;
    font-size: $font-size-base/1.5;
    padding: .5rem;
    border-radius: 1rem;
    box-shadow: 0 0 .4rem #222222;
  }
}

div.pagination {
  font-size: $font-size-base/1.2;
  color: $gray-500;

  a {
    color: $article-link-color;
    text-decoration: underline;

    &:hover {
      color: $article-link-color-hover;
      text-decoration: underline;
    }
  }
}

img.illustration {
  padding: .1rem;
  border: 1px solid $gray-600;
  border-radius: .1rem;
  box-shadow: 0 0 .1rem $gray-600;
  margin-bottom: 1rem;
}

img.thumbnail {
  float: left;
  margin: .5rem 1rem 0 0;
}

nav.sommaire {
  position: sticky;
  top: 1rem;
}

code {
  color: $gray-300;
}

nav[role="navigation"] input[type="checkbox"].menu {
	position: absolute;
	top: -9999px;
	left: -9999px;
}

nav[role="navigation"] label.menu {
	display: none;
	cursor: pointer;
	user-select: none;
}
/* gestion de l’affichage pour les petits écrans */
@media screen and (max-width: 900px) {
  html, body {
    margin: 0;

    header .brand {
      font-size: 1.5rem;
    }

    .infos {
      width: 100%;
      font-size: $font-size-base*.65;
    }

    #mainheader {
      .w30, .w10, .w60 {
        width: 90%;
      }
    }
  }
  nav[role="navigation"] ul,
  nav[role="navigation"] div {
    display: none;
  }
  nav[role="navigation"] label.menu {
    display: inline;
    width: 100%;
    margin-top: .5rem;
  }
  nav[role="navigation"] label.menu:after {
    position: absolute;
    right: 2rem;
    top: 1rem;
    content: "\2261";
    color: white;
  }
  nav[role="navigation"] input[type=checkbox].menu:checked ~ label:after {
    content: "\203A";
  }
  nav[role="navigation"] input[type=checkbox].menu:checked ~ ul,
  nav[role="navigation"] input[type=checkbox].menu:checked ~ div {
    display: block;
    font-size: 1.5rem;
  }
  .w20, .w50, .w60 {
    .mrm, .mlm {
      margin: 0 0 1rem 0;
      padding: 0 1rem;
    }
    width: 90%;
    margin: auto;
  }
  .pal, .pam {
    padding: .5rem;
  }
}